module.exports = {
  siteTitle: 'Hayden Shaw | Web Developer',
  manifestName: 'Hayden Shaw',
  manifestShortName: 'Hayden Shaw',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#ff1493',
  manifestThemeColor: '#ff1493',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/images/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Hayden Shaw',
  heading: 'Web Developer',
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/thats4shaw',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://linkedin.com/in/hayden-shaw',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:shaw.hayden@gmail.com',
    },
  ],
};
